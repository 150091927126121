import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface FileError {
  err_msg: string;
  err_data: any;
}

@Injectable({
  providedIn: 'root'
})


export class CommonObservableService {

  clientName: any
  public dataupdateMessageNext = new BehaviorSubject(false);
  public dataupdateMessage = new BehaviorSubject(false);
  public checkCourseInvalidation = new BehaviorSubject(true);
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  public checkStepperForm = new BehaviorSubject('');
  public sendTheData = new BehaviorSubject('');
  public loaderMessage = new BehaviorSubject('');
  public exitButton = new BehaviorSubject(true);
  public tid = new BehaviorSubject(false);
  public isEdit = new BehaviorSubject(false);
  public isMemberProfile = new BehaviorSubject(false);
  public isPatch = new BehaviorSubject(false);
  public isModelOpen = new BehaviorSubject(false);
  public isFilesUploadError = new BehaviorSubject<FileError>(null);
  public isClientUploadError = new BehaviorSubject<FileError>(null);
  public clientisEdit = new BehaviorSubject(false);
  public clientisModelOpen = new BehaviorSubject(false);
  public updateSideBarStatusVariable = new BehaviorSubject('');
  public updateUserFormVariableVariable = new BehaviorSubject('');
  public updateUserFormAnimationStateVariable = new BehaviorSubject('out');
  public userToUpdate = new BehaviorSubject('');
  public getGlobalSearchVariable = new BehaviorSubject('');
  public updateUserFormStateVariable = new BehaviorSubject('add');
  public previoursIdClient = new BehaviorSubject('');
  public client_admin_data = new BehaviorSubject('')
  public open_from_dashboard = new BehaviorSubject('')
  // public sendAgeGroupStateVariable = new BehaviorSubject([]);
  // public sendTagsStateVariable = new BehaviorSubject([]);

  public client_report_data = new BehaviorSubject('');
  public uData = new BehaviorSubject('');
  // sendId = this.client_report_data.asObservable();
  public dataSource: BehaviorSubject<string> = new BehaviorSubject<string>('{value:0}');
  data: Observable<string> = this.dataSource.asObservable();

  public dataSourceForClientHealthReport = new BehaviorSubject('');

  public modalvaluedashboard = new BehaviorSubject('');
  public close_modal = new BehaviorSubject('');
  public hidePass = new BehaviorSubject('');

  public getIndustrySearchData = new BehaviorSubject('');
  public dateValue = new BehaviorSubject('add');


  public userValue = new BehaviorSubject("hii");
  public clientValue = new BehaviorSubject("");
  public companyvalue = new BehaviorSubject("");
  public companyAdminvalue = new BehaviorSubject("");
  public expertValue = new BehaviorSubject("")

  public totalCountValue = new BehaviorSubject('add');
  public statsdata = new BehaviorSubject('');
  public coursedata = new BehaviorSubject('');
  public compandata = new BehaviorSubject("")

  public useriddata = new BehaviorSubject('');
  public activeTab = new BehaviorSubject('');
  public addUserIconValue = new BehaviorSubject(null);
  public addCompanyIconValue = new BehaviorSubject(null);
  public addClientIconValue = new BehaviorSubject(null);
  public sharingClientDataForCompanyCount = new BehaviorSubject('');
  public isOpendFromExternal = new BehaviorSubject('');
  private sharedData: BehaviorSubject<any> = new BehaviorSubject<any>('');

  sharedData$: Observable<any> = this.sharedData.asObservable();

  public sendAllTypeUsersData = new BehaviorSubject('');
  public updateTeacherClients = new BehaviorSubject(false);
  public resetAllFilterObs = new BehaviorSubject({ reset: '', date_range: '' });
  public companyIdData = new BehaviorSubject("")
  public filterValue =new BehaviorSubject("")
  private navbar = new BehaviorSubject<any>(null);
  constructor() {
  }
  /*setData observable for updating the CHild Components Pages Unique Identity 
  It will resolve the whenever the api's conflict issue get arise*/

  emitNavbarEvent(data: any) {
    this.navbar.next(data);
  }
  getNavbarEvent(): Observable<any> {
    return this.navbar.asObservable();
  }
  updateData(data: any) {
    this.dataSubject.next(data);
  }
  setData(updatedData: any) {

    this.sharedData.next(updatedData);
  }

  /*Observable which will update the global date value*/
  updateDateValue(value: any) {
    this.dateValue.next(value);
    return this.dateValue.asObservable()

  }
  /*Observable which will update the global resetAllFilterObs value*/
  updateResetAllFilterObs(value: any) {
    this.resetAllFilterObs.next(value);
  }
  /*It will update the total count*/
  updateTotalCountValue(value: any) {
    this.totalCountValue.next(value)
  }

  /*It will update the total count*/
  sharingDataForCompanies(value: any) {
    this.sharingClientDataForCompanyCount.next(value)
  }
  /*It will update the show hide stats*/
  stats(value: any) {
    this.statsdata.next(value)

  }
  /**updating course data from the advanced filter to table filter */
  Coursedata(value: any) {
    this.coursedata.next(value)
  }

  companyrow(value: any) {
    this.compandata.next(value)
  }

  /*sharing user id and role_Id from dashboard user component to user profile management component*/
  userid(value: any) {
    this.useriddata.next(value)
  }

  /*It will update the search navbar value*/
  sendSearchValueData(data: any) {
    this.dataSource.next(data);
    return this.dataSource.asObservable()
  }


  sendSearchValueDataForClientHealthReport(data: any) {
    this.dataSourceForClientHealthReport.next(data);
  }
 
  sendUserValueData(data: any) {
    this.userValue.next(data);
  }

  sendClientValueData(data: any) {
    this.clientValue.next(data);
  }
  sendCompanyValueData(data: any) {
    this.companyvalue.next(data)
  }
  sendCompanyAdminValueData(data: any) {
    this.companyAdminvalue.next(data)
  }
  sendExpertValueData(data: any) {
    this.expertValue.next(data);
  }
  closemodalofuserdashboard(value: any) {
    this.modalvaluedashboard.next(value)
  }
  closemodal(value: any) {

    this.close_modal.next(value)
  }
  hidePassword(value: any) {

    this.hidePass.next(value)
  }
  updateIsMemberProfile(value: boolean) {
    this.isMemberProfile.next(value);
  }

  updateIsEdit(value: boolean) {
    this.isEdit.next(value);

  }
  CheckstepperForm(value: any) {
    this.checkStepperForm.next(value)
  }
  checkCourseInvalid(value:any){
    this.checkCourseInvalidation.next(value)
  }
  
  dataupdateMes(value:any){
    this.dataupdateMessage.next(value)
  }
  dataupdateMessag(value:any){
    this.dataupdateMessageNext.next(value)
  }
  sendData(value: any) {
    this.sendTheData.next(value)
  }
  loadermessage(value: any) {
    this.loaderMessage.next(value)
  }
  exitButtonDisable(value:any){
    this.exitButton.next(value)
  }
  
  getTeacherId(value: any) {
    this.tid.next(value)
  }

  updateIsPatch(value: boolean) {
    this.isPatch.next(value);
  }
  clientAdminData(value: any) {
    this.client_admin_data.next(value)
  }
  isOpenfromDashboard(value: any) {
    this.open_from_dashboard = value
  }
  updateIsModelOpen(value: boolean) {
    this.isModelOpen.next(value);
  }

  updateFilesUploadError(value: FileError) {
    this.isFilesUploadError.next(value);
  }

  clientNameFunct(e) {
    this.clientName = e;
  }

  clientupdateIsEdit(value: boolean) {
    this.clientisEdit.next(value);
  }
  getclientupdateIsEdit() {
    return this.clientisEdit.value;
  }

  clientupdateIsModelOpen(value: boolean) {
    this.clientisModelOpen.next(value);
  }
  updateTeacherClient(value: boolean) {
    this.updateTeacherClients.next(value);
  }
  previoursIdOfClient(value: any) {
    this.previoursIdClient.next(value)
  }

  updateClientUploadError(value: FileError) {
    this.isClientUploadError.next(value);
  }

  updateSideBarStatus(value: any) {
    this.updateSideBarStatusVariable.next(value);
  }

  updateUserFormVariable(value: any) {
    this.updateUserFormVariableVariable.next(value);
  }
  isOpendFromExternalcompany(value: any) {
    this.isOpendFromExternal.next(value)
  }
  updateUserFormAnimationState(value: any) {
    this.updateUserFormAnimationStateVariable.next(value);
  }
  updateUserToUpdate(value: any) {
    this.userToUpdate.next(value);
  }
  getUserFormAnimationState() {
    return this.updateUserFormAnimationStateVariable.value;
  }
  getGlobalSearch(value: any) {
    this.getGlobalSearchVariable.next(value);
  }
  updateUserFormState(value: any) {
    this.updateUserFormStateVariable.next(value);
  }

  // sendAgeGroups(value: any) {
  //   this.sendAgeGroupStateVariable.next(value);
  // }

  // sendTags(value: any) {
  //   this.sendTagsStateVariable.next(value);
  // }

  sendReportId(value) {
    this.client_report_data.next(value);
  }
  sendUdata(value){
    this.uData.next(value)
  }
  activeTabStatus(value) {
    this.activeTab.next(value)
  }


  getIndustrySearch(value: any) {
    this.getIndustrySearchData.next(value);
  }
  sendAllTypeUsers(value: any) {
    this.sendAllTypeUsersData.next(value);
  }
  sendCompanyIds(data:any){
    this.companyIdData.next(data);
  }
  filtersValue(data:any){
    this.filterValue.next(data)
  }
  
}


