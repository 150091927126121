import { environment } from 'environments/environment';
export const validations = {
  passingScore: /^[1-9]\d*(\.\d+)?$/,
  DomainFormat: /^([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,6})$/,
  // emailFormat: /^([\w.,!?:;&#%’'"()«»À-Üà-øoù-ÿŒœŸ€])+\@([\w.,!?:;&#%’'"()«»À-Üà-øoù-ÿŒœŸ€])+\.([\w.,!?:;&#%’'"()«»À-Üà-øoù-ÿŒœŸ€]{2,6})$/,
  // emailFormat:/^(?!.*[._'%+-][._'%+-])[a-zA-Z0-9_'%+-àâäæçèéêëîïôœùûüÀÂÄÆÇÈÉÊËÎÏÔŒÙÛÜ]+@[a-zA-Z0-9._'%+-àâäæçèéêëîïôœùûüÀÂÄÆÇÈÉÊËÎÏÔŒÙÛÜ]+\.[a-zA-ZàâäæçèéêëîïôœùûüÀÂÄÆÇÈÉÊËÎÏÔŒÙÛÜ]{2,6}$/,
  emailFormat: /^(?!.*[._'’`%+-][._'’`%+-])[a-zA-Z0-9_'’`%+-åāãáàâäæçèéêëęēîïīíìôöóòÿøœúùûüūÁÀÃÅĀÂÄÆÇÈÉÊËĘĒÎÏĪÍÌÔÖÓÒŸŒÙÛÜŪÚ]+@[a-zA-Z0-9._'’`%+-åāãáàâäæçèéêëęēîïīíìôöóòÿøœúùûüūÁÀÃÅĀÂÄÆÇÈÉÊËĘĒÎÏĪÍÌÔÖÓÒŸŒÙÛÜŪÚ]+\.[a-zA-ZåāãáàâäæçèéêëęēîïīíìôöóòÿøœúùûüūÁÃÀÅĀÂÄÆÇÈÉÊËĘĒÎÏĪÍÌÔÖÓÒŸŒÙÛÜŪÚ]{2,6}$/, // changes in pattern(14-05-2024)

  passwordFormat:environment.passwordFormat,
  passwordFormat1: environment.passwordFormat1,
  strongPassword: environment.strongPassword,
  mediumPassword: environment.mediumPassword,
  alpha: 'abcdefghijklmnopqrstuvwxyz',
  caps: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  numeric: '0123456789',
  special: '#?!@$%^&*-',
  onlynumeric: '^[0-9]*$',
  // mobileNoFormat: "^((\\+91-?)|0)?[0-9]{10,15}$",
  mobileNoFormat:"^(\\+\\d{1,2}\\s)?(\\(\\d{3}\\)|\\d{3})[\\s./-]?\\d{3}[\\s./-]?\\d{4}$",
  // mobileNoFormat:/^(?=.*[0-9])[- /+()0-9]+$/,
  mobilemaxlength: 15,
  debounceSearchTime: 800,
  debounceDefaultTime: 300,
  debounceMinimumTime: 100,
  sourceidMaxlength: 10,
  defaultLength: 60,
  svgSize: 3,
  imageSize: 3,
  documentSize: 3,
  documentSize01: 100,
  bulkUploadFileSize: 3,
  videoSize: 100,
  zipSize: 100,
  dataTableResetTime: 600,
  csvSize: 10,
  passingScoreLength: 10,
  accesscode: 250,
  classcode: 250,
  nameMaxLength: 500,
  bookNamesearchMaxLength: 100,
  titleAfterBeforeSearchMaxLength: 100,
  questionpollsearchMaxLength: 100,
  companyNameMaxLength: 100,
  clientNameMaxLength: 100,
  industryMaxLength: 50,
  firstNameMaxLength: 100,
  lastNameMaxLength: 100,
  emailMaxLenght: 100,
  emailSubjectMaxLenght: 500,
  usernameMaxLenght: 100,
  passwordMaxLenght: 200,
  cityMaxLenght: 500,
  mobileMaxlength: 15,
  videoMaxLength: 500,
  urlMaxLength: 500,
  assesmentCallsMaxlength: 5,
  moduleNameMaxLength: 100,
  noOfQuestionsMaxLength: 20,
  domainMaxLength: 100,
  courseTitleMaxLength: 100,
  messageMaxLength: 150,
  descriptionMaxLength: 500,
  textMaxLenght: 400,
  topicNameMaxLength: 100,
  videoNameMaxLength: 50,
  questionMaxLength: 500,
  questionSearchMaxLength: 500,
  rightAnswerMaxLength: 200,
  answerHintMaxLength: 200,
  optionsMaxLength: 200,
  bookNameMaxLength: 149,
  authorMaxLength: 99,
  titleAfterBeforeMaxLength: 200,
  quoteMaxLength: 100,
  designationMaxLength: 100,
  expertNameMaxLength: 200,
  eventNameMaxLength: 100,
  locationMaxLength: 100,
  downloadTilteMaxLength: 100,
  ageGroupTilteMaxLength: 100,
  headingTilteMaxLength: 100,
  roleNameMaxLength: 100,
  emailTitleMaxLength: 100,
  emailSubjectMaxLength: 100,
  metaDescriptionMaxLength: 500,
  postTitleMaxLength: 200,
  blogSearchMaxLength: 300,
  blogCommentFullName: 100,
  blogCommentEmail: 100,
  blogCommentComment: 500,
  TestimonialNameMaxLength: 100,
  testimonialSectorMaxlength: 500,
  testimonialJobtitleMaxlength: 500,
  testimonialFreedomCoachMaxlength: 50,
  testimonialTextMaxlength: 500,
  testimonialOrderMaxlength: 100,
  albertaNameSearchMaxlength: 100,
  albertaTeacherMaxlength: 100,
  albertaSchoolMaxlength: 100,
  emailLogNameMaxlength: 100,
  emailLogSubjectMaxlength: 200,
  imageNameMaxlength: 100,
  staffNameMaxlength: 100,
  roleNameMaxlength: 100,

};