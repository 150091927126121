import { Injectable, SecurityContext } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { validations } from 'app/module/validation';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable()
export class CommonValidationService {

  constructor(private domSanitizer: DomSanitizer) {}

  trimValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      control.valueChanges.pipe(debounceTime(2000)).subscribe(value => {
        if (control.value) control.patchValue(control.value.trim())
      });
      return null;
    };
  }

  fileExtensionValidator(validExt: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = true;
      if (control.value) {
        const fileExt = control.value.split('.').pop();
        validExt.split(',').forEach(ext => {
          if (ext.trim() == fileExt) forbidden = false;
        });
      }
      return forbidden && control.value ? { 'inValidExt': true } : null;
    };
  }

  certificateTimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = true;
      if (control.value) {
        let isColonExist = control.value.includes(":");
        if (isColonExist) {
          let data = control.value.split(":");
          if (data[1] <= 60 && (data[1].length == 1 || data[1].length == 2)) forbidden = false;
        }
      }
      return forbidden && control.value ? { 'inValidTime': true } : null;
    };
  }

  fileSizeValidator(validSize: number, size: number): { [key: string]: any } | null {
    let forbidden = true;
    let fileInMb = Math.round(size / 1024) / 1024;
    if (fileInMb < validSize) forbidden = false;
    return forbidden ? { 'inValidSize': true } : null;
  }

  spaceNotAllowedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = true;
      if (control.value) forbidden = /\s/g.test(control.value);
      return forbidden && control.value ? { 'space': true } : null;
    };
  }

  documentImg(name: string) {
    const fileExt = name.split('.').pop();
    let img;
    switch (fileExt) {
      case 'doc':
        img = 'doc.png';
        break;
      case 'pdf':
        img = 'pdf.png';
        break;
      case 'docx':
        img = 'docx.png';
        break;
      case 'txt':
        img = 'txt.jpg';
        break;
      case 'csv':
        img = 'csv.png';
        break;
      case 'xls':
        img = 'xls.jpg';
        break;
      case 'xlsx':
        img = 'xlsx.png';
        break;
      case 'xlsm':
        img = 'xlsm.png';
        break;
      default:
        img = 'ivalid-file.jpg';
    }
    return img;
  }


  createPasswordString() {
    var possible = '';
    var possible2 = '';
    var possible3 = '';
    var possible4 = '';
    var possible5 = '';
    possible += 'abcdefghijklmnopqrstuvwxyz';
    possible2 += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    possible3 += '0123456789';
    possible4 += '!@#$%&*?';
    possible5 += Date.now();
    var text = '';
    var text2 = '';
    var text3 = '';
    var text4 = '';
    var text5 = '';
    for (var i = 0; i < 2; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    for (var i = 0; i < 2; i++) {
      text2 += possible2.charAt(Math.floor(Math.random() * possible2.length));
    }
    for (var i = 0; i < 2; i++) {
      text3 += possible3.charAt(Math.floor(Math.random() * possible3.length));
    }
    for (var i = 0; i < 2; i++) {
      text4 += `${possible4.charAt(Math.floor(Math.random() * possible4.length))}`;
    }
    for (var i = 0; i < 2; i++) {
      text5 += `${possible5.charAt(Math.floor(Math.random() * possible5.length))}`;
    }
    return text + text2 + text3 + text4 + text5;
  }


  createusernameString(data) {
    var possible = '';
    var possible2 = '';
    possible += data;
    possible2 += Date.now();
    var text = '';
    var text2 = '';
    for (var i = 0; i < 3; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    for (var i = 0; i < 5; i++) {
      text2 += possible2.charAt(Math.floor(Math.random() * possible2.length));
    }
    return text + text2;
  }


  createUserNameString(firstname: string, lastname: string) {
    let username = '';
    if (firstname && lastname) {
      username = `${firstname.toLowerCase().trim().replace(/ /g, '_')}_${lastname.toLowerCase().trim().replace(/ /g, '_')}`;
    } else if (firstname) {
      username = firstname.toLowerCase().trim().replace(/ /g, '_');
    } else if (lastname) {
      username = lastname.toLowerCase().trim().replace(/ /g, '_');
    } else{
      username = '';
    }

    if(username != ''){
    const randomDigits = Math.floor(Math.random() * 10000);
    username += randomDigits.toString().padStart(4, '0');
    }

    return username;
  }


  createFirstNameString(client_name: string, firstname: string, lastname: string) {
    let teacher_name = '';
    if (firstname && lastname) {
      teacher_name = `${client_name} - ${firstname.trim().replace(/ /g, '_')} ${lastname.trim().replace(/ /g, '_')}`;
    } else if (firstname) {
      teacher_name = `${client_name} - ${firstname.trim().replace(/ /g, '_')}`;
    } else if (lastname) {
      teacher_name = `${client_name} - ${lastname.trim().replace(/ /g, '_')}`;
    } else{
      teacher_name = `${client_name}`;
    }

    return teacher_name;
  }
  passwordStrength(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const strong = new RegExp(validations.strongPassword);
      const medium = new RegExp(validations.mediumPassword);
      if (strong.test(control.value)) return { 'strong': true };
      else if (medium.test(control.value)) return { 'medium': true };
      else if (control.value) return { 'weak': true };
      else return null;
    };
  }

  createGenericPasswordStringmain(nameClient) {
    var possible = '';
    var possible2 = '';
    var possible3 = '';
    possible += nameClient;
    possible2 += '@#';
    possible3 += '0123456789';
    var text = '';
    var text2 = '';
    var text3 = '';
    let data = possible.split(' '); let output = "";
    for (var i = 0; i < data.length; i++) { output += data[i].substring(0, 1); }
    text = output.toLowerCase();
   
    text2 = possible2;
    
    for (var i = 0; i < 4; i++) {
      text3 += possible3.charAt(Math.floor(Math.random() * possible3.length));
    }    
    return text + text2 + text3;
  }
  createGenericPasswordString(nameClient) {
    var possible = '';
    var possible2 = '';
    var possible3 = '';
    possible += nameClient;
    possible2 += '@#!$';
    possible3 += '0123456789';
    var text = '';
    var text2 = '';
    var text3 = '';
    let data = possible.split(' '); let output = "";
    for (var i = 0; i < data.length; i++) { output += data[i].substring(0, 1); }
    text = output
    for (var i = 0; i < 2; i++) {
      text2 += possible2.charAt(Math.floor(Math.random() * possible2.length));
    }
    for (var i = 0; i < 4; i++) {
      text3 += possible3.charAt(Math.floor(Math.random() * possible3.length));
    }
    return text + text2 + text3;
  }

}