import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable()
export class HttpFrontEndService {
  email: any
  pass: any
  ip: any = '';

  private baseUrl = environment.FRONTEND_BASE_URI;
  // AUTH_TOKEN = 'auth_token';
  AUTH_TOKEN = environment.auth_token;
  // access_token = '';
  language = localStorage.getItem('language') ?? 'en';
  headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
  constructor(private httpClient: HttpClient, private toastr: ToastrService, private deviceService: DeviceDetectorService, private authService: AuthenticationService,) {
    // this.getUserDetails()
  }

  get(url: string, params?: any): Observable<any> {
    // const data = {params, headers: this.getAuthHeader()};
    const headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.get(this.baseUrl + url, config).pipe(catchError(this.errorHandler.bind(this)));
    // .get(url, data).pipe(catchError(this.errorHandler.bind(this)));
  }

  post(url: string, body: any, param?: any): Observable<any> {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.post(this.baseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
  }

  put(url: string, body: any, params?: any): Observable<any> {
    return this.httpClient.put(this.baseUrl + url, body).pipe(catchError(this.errorHandler.bind(this)));
  }

  delete(url: string, params?: any): Observable<any> {
    return this.httpClient.delete(url).pipe(catchError(this.errorHandler.bind(this)));
  }

  getIPAddress() {
    return this.get(environment.IP);
  }

  private errorHandler(response: any) {
    this.toastr.error('', environment.serverErrorMessage);
    const error = response.error;
    let message;
    if (error) {

      const keys = Object.keys(error);
      const key = keys[0];
      message = error[key];
      if (response.status === 401) {
        // auth token delete
        // redirect login page
      }
      if (error[key] instanceof Array) {
        message = error[key][0];
      }
      if (key === 'isTrusted') {
        // this will occur when not connected to internet
      } else {
        message = key + ' : ' + message;
      }
    } else {
      message = response;
    }
    // call snackbar and show error with message
    return throwError({ messages: message, error });
  }

  // private getAuthHeader(): { [header: string]: string | string[]; } {
  //   return {
  //     Authorization: `Bearer ${localStorage.getItem(this.AUTH_TOKEN)}`
  //   };
  // }

  // defaultProfilePicture = 'admin/image/avatar1.webp';
  // setProfilePicture(name) {
  //   this.defaultProfilePicture = name;
  // }

  // async getUserDetails() {
  //   let IsLogin: any = (JSON.parse(localStorage.getItem('currentUser')));
  //   if (IsLogin) {
  //     let data1 = { role_id: IsLogin.role_id, id: IsLogin.id }
  //     let result = await this.post('admin/getProfileSettingData', data1).toPromise();
  //     if (result.status) {
  //       let data = result.data[0];
  //       this.defaultProfilePicture = 'admin/image/' + data.title
  //       this.currUser = data
  //     }
  //     let data = []
  //   }
  // }
  // currUser: any = []
  // admindata: any = []
  // adminProfileData(user, profiledata) {
  //   this.currUser = user
  //   this.admindata = profiledata
  // }

  // PostProgress(url, value, param) {
  //   let headers
  //   if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
  //   else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
  //   param['headers'] = headers;
  //   return this.httpClient.post(url, value, param)
  // }

  // async saveActivityLog(activity_type: string, pageData: any, targetSection: string, data: string) {
  //   let sData: any = {};
  //   let _access = JSON.parse(localStorage.getItem('currentUser'));

  //   sData['pageUrl'] = pageData.url;
  //   sData['pageName'] = pageData.pagename
  //   sData['targetSection'] = targetSection;
  //   sData['description'] = pageData.description;
  //   sData['data'] = data;


  //   let ip: any = await this.getLocalIPAddress();
  //   sData['ip'] = ip;
  //   let deviceInfo = this.deviceService.getDeviceInfo();
  //   sData['browser'] = deviceInfo?.browser;
  //   sData['os'] = deviceInfo?.os;
  //   sData['deviceType'] = deviceInfo?.deviceType;

  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       sData['longitude'] = position.coords.longitude;
  //       sData['latitude'] = position.coords.latitude;
  //       // console.log(sData.longitude,'geolocation')

  //     });
  //   } else {
  //     sData['longitude'] = 0
  //     sData['latitude'] = 0;
  //   }
  //   let finalData = {
  //     admin_id: _access == null ? 0 : _access?.id, activity_type: activity_type,
  //     data: sData, infosource: 'code', ip_address: ip,
  //     isLoader: 'false'
  //   }
  //   // console.log(finalData, 'finaldata')
  //   let response = await this.post('saveActivityLogs', finalData).toPromise();
  //   return response;
  // }
  async getLocalIPAddress() {
    if (this.ip == '') {
      let ip: any = await this.get(environment.IP).toPromise();
      this.ip = ip['ip'];
      return this.ip;
    } else
      return this.ip;
  }

}
