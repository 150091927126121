import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe-html.pipe';

const modules = [SafePipe];

@NgModule({
  declarations: modules,
  imports: [
    CommonModule
  ],
  exports: modules
})
export class SafeHtmlModule { }
