import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('fffffffffffffffffffffffffffffff');
    
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(['/pages/miscellaneous/not-authorized']);
        return false;
      }
      // authorised so return true
      if (localStorage.getItem('is_verified') == 'yes') {
        return true;
      }
      // for loading issue
      if(localStorage.getItem('is_verified') == 'no'){
        localStorage.clear()
        location.reload()
        // this._router.navigate(['/admin/login']);
        return false;
      }
    } else {
     
      // not logged in so redirect to login page with the return url
      this._router.navigate(['/admin/login']);
      return false;
    }
  }
}


@Injectable()
export class AnonGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) { }


  canActivate(route: ActivatedRouteSnapshot): any {
    console.log('ggggggggggggggggggggggggg');

    if (location.href.search('resetpassword') != -1) {
      return true;
    } else {
      const isLoggedIn = this._authenticationService.currentUserValue;
      if (isLoggedIn) {this._router.navigate(['/admin/dashboard']);}
      else { 
        // If only admin get in url then
        if(route['_routerState'].url == '/admin'){
            this._router.navigate(['/admin/login']);
          return false;
        }
         else {
          return true;
        }
     
        }
    }
  }
}


@Injectable()
export class AnonLoginGuard implements CanActivate {

    constructor(
        private router: Router,
        private _authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot): any {
    console.log('hhhhhhhhhhhhhhhhhh');

        const isLoggedIn = this._authenticationService.isLogin();
        if (isLoggedIn) this.router.navigate(['/admin/dashboard']);
        else return true;
    }
}
