export const target_section = {
    goto_dasboard: "Navigate user dashboard according to admin role.",
    goto_events_and_webinars: "Navigated to events and webinars page",

    edit_event:'edit_event_and_webinars',
    status_update_comments: "Status update comments",
    delete_post_comments: "Delete post comments",
    edit_post_comments: "Edit post comments buttons",
    update_comments: "Update comments",
    add_comments: "Add comments",
    delete_post_blog: "delete post blog",
    update_post_blog: "Update Post Blog",
    add_post_blog: "Add Post Blog",
    delete_companies : "Companies deleted",
    update_companies: "Update Companies",
    add_companies: "Add companies",
    Status_companies: "Status update Companies",
    export_companies_button_clicked: "companies export button clicked",
    bulk_upload_companies :"bulk upload companies",
    update_company_admins: "Update company admin data",
    add_company_admins: "Add company admin data",
    delete_company_admins: "Delete company admin data",
    Status_company_admins: "Status update company admins",
    export_company_admin_clicked: "company admin export button clicked",
    bulk_upload_companies_admin :"bulk upload companies admin",
    update_email_template : "Update email template data",
    add_email_template : "Add email template data",
    delete_email_template: "Delete_email_template data",
    status_email_template : "Status email template update",
    update_user_management : "Update users data",
    add_user_management :  "Add users data",
    delete_user_management : "Users deleted",
    status_user_management : "Status update users",
    user_export_clicked: "users export button clicked",
    user_bulk_upload :"bulk upload users",
    update_client_admin: "Update client admin data",
    add_client_admin: "Add client admin",
    delete_client_admin: "client admin deleted",
    delete_client_temporary: "client temporary deleted",
    status_client_admin:"Status update client admin data",
    activate_client_temporary:"Activate client temporary account",
    client_admin_export_clicked: "client admin export button clicked",
    client_admin_bulk_uploal :"bulk upload client admin",
    update_client : "Update client data",
    add_client: "Add client data",
    delete_client: "Delete client",
    status_client: "Status update Client data",
    client_export_clicked: "Client export button clicked",
    client_permission_add: "Add permission forms",
    client_permission_update: "Update permission forms data",
    client_certificate_add: "Add certificate customization forms",
    client_certificate_update: "Update certificate customization forms data",
    client_social_links_add: "add Client social links",
    clien_social_lings_update: "update client social links",
    client_bulk_upload: "bulk upload clients",
    Client_course_config_add: "Add course config form data",
    Client_course_config_update:"update course config form data",
    staffs_add: "Add staffs data",
    staffs_update : "Update staffs data",
    staffs_status_update: "Staffs Status update",
    staffs_delete: "delete staffs data",
    roles_add: "Add roles data",
    roles_update : "Update roles data",
    roles_status_update: "roles Status update",
    roles_delete: "delet roles data",
    profile_image: "profile image update",
    profile_update: "profile update data",
    change_password: "update password",
    logout_users: "logout users",
    sign_in: "login users.",
    like_dislike_status: "like dislike update status",
    forgot_password : "user forgot passwrod.",
    otp_verify: "user otp verify",
    otp_reset: "user otp reset",
    set_new_password: "set user new password",


    delete_events_and_webinars:'deleted events and webinars data',
    edit_events_and_webinars:'edited events and webinars data',
    added_events_and_webinars:'added events and webinars data',
    status_events_and_webinars:'changed status of events and webinars data',

    delete_module:'deleted module data',
    edit_module:'edited module data',
    added_module:'added module data',
    status_manage_modules:'changed status of modules data',

    delete_topic:'deleted topic data',
    edit_topic:'edited topic data',
    added_topic:'added topic data',
    status_manage_topic:'changed status of topic data',

    delete_video:'deleted video data',
    edit_video:'edited video data',
    added_video:'added video data',
    status_manage_video:'changed status of video data',

    delete_question:'deleted question data',
    edit_question:'edited question data',
    added_question:'added question data',
    status_manage_question:'changed status of question data',

    delete_tile:'deleted tile data',
    edit_tile:'edited tile data',
    added_tile:'added tile data',
    status_manage_tile:'changed status of tile data',

    delete_download:'deleted download data',
    edit_download:'edited download data',
    added_download:'added download data',
    status_manage_download:'changed status of download data',

    delete_expert_interview:'deleted expert interview data',
    edit_expert_interview:'edited expert interview data',
    added_expert_interview:'added expert interview data',
    status_manage_expert_interview:'changed status of expert interview data',

    delete_footer_banner:'deleted footer banner data',
    edit_footer_banner:'edited footer banner data',
    added_footer_banner:'added footer banner data',
    status_manage_footer_banner:'changed status of footer banner data',

    delete_book:'deleted book data',
    edit_book:'edited book data',
    added_book:'added book data',
    status_manage_book:'changed status of book data',

    delete_age_group:'deleted age group data',
    edit_age_group:'edited age group data',
    added_age_group:'added age group data',
    status_manage_age_group:'changed status of age group data',

    delete_course:'deleted course data',
    edit_course:'edited course data',
    added_course:'added course data',
    status_manage_course:'changed status of course data',

    delete_testimonials:'deleted testimonials data',
    edit_testimonials:'edited testimonials data',
    added_testimonials:'added testimonials data',
    status_manage_testimonials:'changed status of testimonials data',

    delete_email_log:'deleted email log data',
    edit_email_log:'edited email log data',
    added_email_log:'added email log data',
    status_manage_email_log:'changed status of email log data',

    edit_industry:'edited industry data',
    added_industry:'added industry data',
    delete_industry:'deleted industry data',
    status_industry:'changed status of industry data'



}