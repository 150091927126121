import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { CoreMenuItem } from '@core/types';
import { HttpService } from 'app/services/http.service';
import Swal from 'sweetalert2';
import { swalConfig } from 'app/commonconfig/config';
import { CONNREFUSED } from 'dns';
import { CommonObservableService } from 'app/services/common-observable.service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})

export class CoreMenuVerticalItemComponent {

  @Input()
  item: CoreMenuItem;
  swalConfig: any = swalConfig;

  constructor(private httpservice: HttpService, private route: Router, private cos: CommonObservableService) { }
  goToRoute(url: any) {
    if (!this?.route?.url.includes(url)) this.cos.updateUserFormAnimationState('out');


    // if (url == '/user-management/manage-teachers' || url == 'user-management/manage-clients') {
    //   this.route.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
    //     this.route.navigate([url]);
    //   });

    // } else {
    //   this.route.navigate([url])
    // }

    this.route.navigate([url])
    //    console.log(url,'url',this.httpservice.isDirty)

    //   if (this.httpservice.isDirty == false)
    //     this.route.navigate([url])
    //   else {
    //     this.swalConfig.confirmButtonText = "Yes close it!"
    //     Swal.fire(this.swalConfig).then(async (result) => {
    //       console.log(result.value)
    //       if (result.value) {
    //         try {
    //           if (this.httpservice.isDirty == true) {
    //             this.httpservice.isDirty = false
    //             this.route.navigate([url])
    //           }
    //           else {


    //             // this.toastr.error('error');
    //           }
    //         } catch (e) {
    //           // this.toastr.warning('', environment.serverErrorMessage);
    //         }
    //       }
    //     });

    //     return
    //   }        
  }

}

