import moment from "moment"

export const commonconfig = {
  swalTitle: 'Are you sure?',
  swalText: "You will not be able to recover this data!",
  swalText1: "This will remove all data for this record {{vardd}}",
  swalText2: "This will remove all data for this record {{value}}!",
  swalText3: "Do you really want to update parent's company/client, it will affect on their {{value}} members.",
  swalTextDeleteCourse: "This will remove all data for this record ",
  swalTextChangeStatusCourse: "Do you really want to change the status of ",
  swalCourseCopyText: "You want to copy the course",
  swalIcon: 'trash',
  swalbtn: 'btn btn-danger',
  swalconfirmbtn: '#7367F0',
  swalconfirmtext: 'Yes, delete it!',
  swalconfirmtext01: 'Yes',
  swalconfirmChangeStatusCourse: 'Yes',
  swalconfirmtextYes: 'Yes',
  swalbtn2: 'btn btn-primary ml-1',
  swalcancelbtn: '#E42728',
  pagedatalimit1: '10',
  pagedatalimit2: '20',
  pagedatalimit3: '50',
  pagedatalimit4: '100',
  pagedatalimit5: '500',
  pagedatalimitentries: 'entries',
  status1: 'Active',
  status1value: '1',
  status2: 'Inactive',
  status2value: '0',
  sourcetypeAmazon: 'Amazon',
  sourcetypeAmazonvalue: '4',
  sourcetypeLocal: 'Local',
  sourcetypeLocalvalue: '3',
  sourcetypeVimeo: 'Vimeo',
  sourcetypeVimeovalue: '1',
  sourcetypeYoutube: 'Youtube',
  sourcetypeYoutubevalue: '2',
  agegroupAdults: 'Adults',
  agegroupAdultsvalue: '3',
  agegroupKids: 'Kids',
  agegroupKidsvalue: '1',
  agegroupOldAged: 'Old Aged',
  agegroupOldAgedvalue: '4',
  agegroupTeens: 'Teens',
  agegroupTeensvalue: '2',
  agegroupOther: 'Other',
  agegroupOthervalue: '5',
  filetypeWorkbook: 'Workbook',
  filetypeWorkbookvalue: 1,
  filetypeCalculator: 'Calculator',
  filetypeCalculatorvalue: 2,
  filetypeTools: 'Tools',
  filetypeToolsvalue: 3,
  filetypeTeacherAssignment: 'Teacher Assignment',
  filetypeTeacherAssignmentvalue: 4,
  eventtypeEvent: 'Event',
  eventtypeEvemtvalue: 1,
  eventtypeWebinar: 'Webinar',
  eventtypeWebinarvalue: 2,
  timezoneEST: 'EST',
  timezoneESTvalue: 'EST',
  timezoneIST: 'IST',
  timezoneISTvalue: 'IST',
  timezoneEDT: 'EDT',
  timezoneEDTvalue: 'EDT',
  timezoneADT: 'ADT',
  timezoneADTvalue: 'ADT',
  timezonePST: 'PST',
  timezonePSTvalue: 'PST',
  timezonePDT: 'PDT',
  timezonePDTvalue: 'PDT',
  albertEssayavalue: 3,
  alberta: 'Alberta Essay Scholarship',
  postSecondaryvalue: 5,
  postSecondary: 'Post Secondary Scholarship',
  nationalScholarshipvalue: 6,
  nationalScholarship: 'Natioanl Scholarship',
  grade10value: 'Grade 10',
  grade10: 'Grade 10',
  grade11value: 'Grade 11',
  grade11: 'Grade 11',
  grade12value: 'Grade 12',
  grade12: 'Grade 12',
  gradeAll: 'All Grade',
  gradeAllvalue: '',
  likevalue: 1,
  like: 'Like',
  dislikevalue: 2,
  dislike: 'Dislike',
  okButton: 'ok',
  cancelButton: 'cancel',
  swelicon: 'question',
  sweltextuserstatus: 'Do you really want to change the status of {{text}}?',
  sweltextuserexport: 'Do you really want to export {{text}} data?',
  sweltextexport: 'Do you really want to export {{text}} data?',
  swelreportexport: 'Do you really want to export {{text}}?',

  swelactivateteacheraccount: 'Do you really want to activate account of {{text}}?',

  swelconfirmbtnclr: '1498c7',
  swelcancelbtnclr: '#E42728',
  sweldeletebtntitle: 'Warning',
  sweldeletetext: `This will remove all data for this record {{text}}!`,

  swelcompanytext: `Please make sure, no user and client exist under this company {{text}}!`,
  swelindustrytext: `Please make sure, no company exist under this industry {{text}}!`,
  swelclienttext: `Please make sure, no user, client admin or teacher exist under this client {{text}}!`,
  swelteachertext: `Please make sure, no user exist under this teacher {{text}}!`,
  placeholder_select_age_group: 'Select',
  placeholder_search_age_group: 'Search',
  placeholder_search_question_nature: 'Search Question Nature',
  placeholder_select_tags: 'Select',
  placeholder_search_tags: 'Search',
  autoComplete: 'off',
  swelindustrytext1: `{{text}} industry cannot be deleted currently due to its {{text1}} company.`,
  swelcompanytext1: `{{text}} company cannot be deleted currently due to its {{text1}}.`,
  swelclienttext1: `{{text}} client cannot be deleted currently due to its {{text1}}.`,
  swelteachertext1: `{{text}} teacher cannot be deleted currently due to its {{text1}}.`,
  
}

export const datepicker = {
  alwaysShowCalendars: false,
  opens: "bottom",
  autoUpdateInput: false,
  showDropdowns: true,
  // minYear: 2010,

  maxYear: 2030,
  linkedCalendars: false,
  autoApply: true,
  startDate: moment().subtract(1, 'year'),
  endDate: moment(),
  maxDate: moment(),
  minDate: moment('01-01-2010'),
  locale: {
    format: 'MMM D, YYYY'
  },
  ranges: {
    'Default': [moment().subtract(11, 'month'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Current Month': [moment().startOf('month'), moment()],
    'Last Full Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Year To Date': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    'Last 2 Years': [moment().subtract(2, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    'Last 3 Years': [moment().subtract(3, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    'Last 5 Years': [moment().subtract(5, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    'Last 10 Years': [moment().subtract(10, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  }
}

export const chosenDate = { start: moment().subtract(12, 'month'), end: moment() }

export const commontypesforvalidation = {
  IMAGE_EXTENSIONS: 'jpg,jpeg,png',
  DOCUMENT_EXTENSIONS: 'doc,pdf,docx,txt,csv,xls,xlsx,xlsm',
  SVG_EXTENSIONS: 'svg',
  URL_REGEX: '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
  ZIP_EXTENSIONS: 'zip',
  CSV_EXTENSIONS: 'csv',
  COMMAN_IMAGE_EXTENSIONS: 'jpg,jpeg,png,svg',
}

export const age_group = [
  { ageid: 3, ageType: 'Adults' },
  { ageid: 1, ageType: 'Kids' },
  { ageid: 4, ageType: 'Old Aged' },
  { ageid: 2, ageType: 'Teens' }
]

export const role = [
  // { roleid: 6, roleType: 'Blog Writter' },
  // { roleid: 4, roleType: 'Company Wide Support' },
  // { roleid: 2, roleType: 'Developer' },
  // { roleid: 5, roleType: 'Division Support' },
  // { roleid: 1, roleType: 'Owner' },
  // { roleid: 3, roleType: 'Staff' },
  { roleid: 6, roleType: 'Hal Kenty (Enriched Academy Financial Coach / CFP)' },
  { roleid: 4, roleType: 'Matt Dewey (Enriched Academy Financial Coach / AFCC)' },
  { roleid: 2, roleType: 'Maegen Kramer (Enriched Academy Financial Coach / CFP)' },
  { roleid: 5, roleType: 'Alanna Abramsky (Enriched Academy Financial Coach / AFCC)' },
  // { roleid: 1, roleType: 'Author 2' },
  // { roleid: 3, roleType: 'Author 1' },
]

export const dropdownstatus = [
  { id: 3, name: 'Archive' },
  { id: 2, name: 'Draft' },
  { id: 0, name: 'Inactive' },
  { id: 1, name: 'Publish' }
]

export const userAccess = [
  { value: 1, label: "Company email authentication" },
  { value: 2, label: "Company import users" }
];

// export const registrationType = [
//   { value: 1, label: "Email" },
//   { value: 2, label: "Other (User Id, Student No.)" }
// ];

export const registrationType = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" }
];

export const PollStatusType = [
  { value: 1, label: "Published" },
  { value: 0, label: "Unpublished" }
];


export const manageChildProfile = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];

export const coursePagination = { limit: 20, offset: 0 }



export const dataTablePageLimit = [
  { value: 10 },
  { value: 20 },
  { value: 50 },
  { value: 100 },
  { value: 500 },
];

export const bannerTitleData = [
  { value: 1, label: "1600x640", icon: "Rectangle_500.svg" },
  { value: 2, label: "300x250", icon: "Group_875.svg" },
  { value: 3, label: "160x160", icon: "Group_876.svg" }
]
export const customizationStandard = [
  { value: 1, label: "Standard" },
  { value: 2, label: "Customized" }
];
export const userAdvanceFilterar:any=[
  // // {id:1,name:"Assignments",selectAll:1},
  // {id:2,name:"Course",selectAll:1},
  // {id:3,name:"Class Code",selectAll:1},
  // {id:4,name:"Last Login ",selectAll:1},
  // {id:5,name:"Registration Date",selectAll:1},
  // {id:6,name:"Status",selectAll:1},
  // {id:3,name:"Certificate Status", disabled: false},
  {id:2,name:"Course", disabled: false},
  {id:4,name:"Last Login ", disabled: false},
  {id:5,name:"Registration Date", disabled: false},
  {id:6,name:"Status", disabled: false},
]
export const users = [
  { label: "Industries", name: "Industry", url: '/user-management/manage-industry' },
  { label: "Companies", name: "Company", url: '/user-management/manage-companies' },
  { label: "Company Admins", name: "Company Admin", url: '/user-management/manage-company-admins' },
  { label: "Clients", name: "Client", url: '/user-management/manage-clients' },
  { label: "Client Admins", name: "Client Admin", url: '/user-management/manage-client-admins' },
  { label: "Teachers", name: "Teacher", url: '/user-management/manage-teachers' },  
  { label: "Review Teachers",name: "Client Temporary", url: '/user-management/manage-client-temporary' },
  { label: "Users", name: "User", url: '/user-management/manage-users' }
];
// please don't change name values of users array. Devnarayan 06/09/2022

export const scholarship = [
  { label: "Alberta", url: '/scholarship-management/alberta-scholarship-master' },
  { label: "Post Secondary", url: '/scholarship-management/post-secondary-master' },
  { label: "National", url: '/scholarship-management/national-scholarship-master' },
  { label: "Manitoba", url: '/scholarship-management/manitoba-scholarship-educator' },



]

export const userType = [
  { value: 1, label: "Parent" },
  { value: 2, label: "Child" }
];

export const profileType = [
  { value: 0, label: "All" },
  { value: 1, label: "Family Member" },
  { value: 2, label: "Parent" }
];

export const dateSearchType = [
  { value: 2, label: "Login Date" },
  { value: 1, label: "Registration" }
];

export const testimonialRelations = [
  { value: "Only my first name", label: "Only my first name" },
  { value: "Only my first name, and the first initial of my last name", label: "Only my first name, and the first initial of my last name" },
  { value: "Use any part of my name", label: "Use any part of my name" },
  { value: "Please do not use my actual name", label: "Please do not use my actual name" }
];
export const giftCard = [
  { value: "$100 Amazon gift card", label: "$100 Amazon gift card" },
  { value: "$100 gift card to The Keg or other restaurant", label: "$100 gift card to The Keg or other restaurant" }
];

export const bannerType = [
  { value: 1, label: "Budget Tools" },
  { value: 2, label: "Dashboard" },
  { value: 3, label: "Dept Crusher" },
  { value: 4, label: "Financial Freedom" },
  { value: 5, label: "Hearing Dashboard" },
  { value: 6, label: "Networth" },
];

export const questionNatureControl = [
   { value: 1, title:'Factual Based' },
   { value: 2, title:'Self-Assessment' }

]

export const swalConfig = {
  title: commonconfig.swalTitle,
  text: commonconfig.swalText,
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: commonconfig.swalconfirmbtn,
  cancelButtonColor: commonconfig.swalcancelbtn,
  confirmButtonText: commonconfig.swalconfirmtext,
  customClass: {
    confirmButton: commonconfig.swalbtn,
    cancelButton: commonconfig.swalbtn2
  }
};

export const CATEGORY_MASTER_COURSE = 1;
export const CATEGORY_MASTER_RESOURCE = 2;
export const CATEGORY_MASTER_VIDEO_TUTORIAL = 3;
export const CATEGORY_MASTER_EXPERT = 4;
export const CATEGORY_MASTER_MODULE = 5;
export const CATEGORY_MASTER_QUESTION = 6;



export let userIsEdit = false;

export const COMMON_MODAL_TYPES = {
  ICON: 'ICON',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  AWS_IMAGE: 'AWS_IMAGE',
  AWS_VIDEO: 'AWS_VIDEO',
  APP_BOOKS_MASTER: 'APP_BOOKS_MASTER',
  APP_VIDEO: 'APP_VIDEO',
  APP_TOPIC: 'APP_TOPIC',
  APP_QUESTION: 'APP_QUESTION',
  VIDEO_LOCAL: 'VIDEO_LOCAL',
}

export const registerd_via = [
  { value: "SuperAdmin", name: 'Super Admin'},
  { value: "SelfRegistered", name:'Self Registered' }
];

export const YOU_TUBE = 'https://www.youtube.com/embed/';
export const VIMEO = 'https://player.vimeo.com/video/';

export const bulkUploadVideoURL = "https://player.vimeo.com/video/419816492?h=1cfe74c502&?autoplay=0&loop=0'"







export const userAdvanceFilter:any=[
  // // {id:1,name:"Assignments",selectAll:1},
  // {id:2,name:"Course",selectAll:1},
  // {id:3,name:"Class Code",selectAll:1},
  // {id:4,name:"Last Login ",selectAll:1},
  // {id:5,name:"Registration Date",selectAll:1},
  // {id:6,name:"Status",selectAll:1},
  {id:3,name:"Certificate Status", disabled: false},
  {id:2,name:"Course", disabled: false},
  {id:4,name:"Last Login ", disabled: false},
  {id:5,name:"Registration Date", disabled: false},
  {id:6,name:"Status", disabled: false},
]





export const statusFilter:any=[
  {id:1,name:"Active"},
  {id:1,name:"Inactive"},
]

export const progressData: any = [
  { id: 1, name: "Completed", selectAll: 1 },
  { id: 2, name: "In Progress", selectAll: 1 },
  { id: 3, name: "Not started", selectAll: 1 }
]



export const toolData: any = [
  { id: 1, name: "Calculator", selectAll: 1 },
  { id: 3, name: "Certificates", selectAll: 1 },
  { id: 2, name: "Tools", selectAll: 1 }
]


export const staffRoles:any = 
  {
    owner:{id:83,name:"Owner"},
    developer:{id:84,name:"Developer"},
    company_wide_support:{id:85,name:"Company Wide Support"},
    division_support:{id:86,name:"Division Support"},
    blog_writing:{id:82,name:"Blog Writing"}
  }

  export const clienttypeData: any = [
    { id: 1, name: "A&C" ,selectAll:1 },
    { id: 2, name: "D2C" ,selectAll:1},
    { id: 3, name: "Education" ,selectAll:1},
    { id: 4, name: "Career College" ,selectAll:1},
    { id: 5, name: "Post-Secondary School" ,selectAll:1},
    { id: 6, name: "High School" ,selectAll:1},
    {id: 7, name:'Elementary',selectAll:1},
    { id: 0, name: "None" ,selectAll:1}
  ]


  // export const clientstatusData: any = [
  //   { id: 1, name: "Client" },
  //   { id: 2, name: "Prospect" },
  //   { id: 3, name: "Staff" },
  //   {id: 4 , name: "Pilot"},
  //   {id: 5 , name: "Former Client"},
  //   {id: 6 , name: "Partner"},
  // ]

  export const clientstatusData = [
    { id: 1, name: 'Client' ,selectAll:1},
    { id: 2, name: 'Prospect' ,selectAll:1},
    { id: 3, name: 'Staff' ,selectAll:1},
    { id: 4, name: 'Pilot' ,selectAll:1},
    { id: 5, name: 'Former Client' ,selectAll:1},
    { id: 6, name: 'Partner' ,selectAll:1},
  ]


  export const webinarType: any = [
    { id: 2, name: "A&C" },
    { id: 1, name: "D2C" },
    { id: 3, name: "Education" }
  ]