import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { formatDate } from '@angular/common';

@Injectable()
export class HttpService {
  email: any
  pass: any
  ip: any = '';

  private baseUrl = environment.apiURL;
  private fronBaseUrl = environment.FRONTEND_BASE_URI;
  private commonBaseUrl = environment.apiUrl;
  // AUTH_TOKEN = 'auth_token';
  AUTH_TOKEN = environment.auth_token;
  // access_token = '';
  language = localStorage.getItem('language') ?? 'en';
  headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
  constructor(private httpClient: HttpClient, private toastr: ToastrService,
    private deviceService: DeviceDetectorService, private authService: AuthenticationService,
    private _authenticationService: AuthenticationService,
  ) {
    this.getUserDetails()
  }

  get(url: string, params?: any): Observable<any> {
    // const data = {params, headers: this.getAuthHeader()};
    const headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.get(this.baseUrl + url, config).pipe(catchError(this.errorHandler.bind(this)));
    // .get(this.baseUrl + url, data).pipe(catchError(this.errorHandler.bind(this)));
  }

  post(url: string, body: any, param?: any): Observable<any> {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.post(this.baseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
  }


  /* Common BASE URI*/

  commonGet(url: string, params?: any): Observable<any> {
    // const data = {params, headers: this.getAuthHeader()};
    const headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.get(this.commonBaseUrl + url, config).pipe(catchError(this.errorHandler.bind(this)));
    // .get(this.baseUrl + url, data).pipe(catchError(this.errorHandler.bind(this)));
  }

  commonPost(url: string, body: any, param?: any): Observable<any> {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.post(this.commonBaseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
  }
  /* Common BASE URI*/


  put(url: string, body: any, params?: any): Observable<any> {
    return this.httpClient.put(this.baseUrl + url, body).pipe(catchError(this.errorHandler.bind(this)));
  }

  delete(url: string, params?: any): Observable<any> {
    return this.httpClient.delete(this.baseUrl + url).pipe(catchError(this.errorHandler.bind(this)));
  }

  getIPAddress() {
    return this.get(environment.IP);
  }

  private errorHandler(response: any) {
    // this.toastr.error('', environment.serverErrorMessage);
    console.log(response,'and',environment.serverErrorMessage)
    const error = response.error;
    let message;
    if (error) {

      const keys = Object.keys(error);
      const key = keys[0];
      message = error[key];
      if (response.status === 401) {
        // auth token delete
        // redirect login page
      }
      if (error[key] instanceof Array) {
        message = error[key][0];
      }
      if (key === 'isTrusted') {
        // this will occur when not connected to internet
      } else {
        message = key + ' : ' + message;
      }
    } else {
      message = response;
    }
    // call snackbar and show error with message
    return throwError({ messages: message, error });
  }

  private getAuthHeader(): { [header: string]: string | string[]; } {
    return {
      Authorization: `Bearer ${localStorage.getItem(this.AUTH_TOKEN)}`
    };
  }

  defaultProfilePicture = 'admin/image/avatar1.webp';
  setProfilePicture(name) {
    this.defaultProfilePicture = name;
  }

  async getUserDetails() {
    let IsLogin: any = this._authenticationService.getLogedInData();
    if (IsLogin) {
      let data1 = { role_id: IsLogin.role_id, id: IsLogin.id }
      return
      let result = await this.post('getProfileSettingData', data1).toPromise();
      if (result.status) {
        let data = result.data[0];
        this.defaultProfilePicture = 'admin/image/' + data.title
        this.currUser = data
      }
      let data = []
    }
  }
  currUser: any = []
  admindata: any = []
  adminProfileData(user, profiledata) {
    this.currUser = user
    this.admindata = profiledata
  }

  PostProgress(url, value, param) {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    param['headers'] = headers;
    return this.httpClient.post(this.baseUrl + url, value, param)
  }

  async saveActivityLog(activity_type: string, pageData: any, targetSection: string, data: string) {
    // console.log('hit api')

    let sData: any = {};
    let _access = this._authenticationService.getLogedInData();
    // console.log(pageData, 'pagedata')
    // console.log(_access, 'access')
    // console.log(activity_type, 'activity_type')


    sData['pageUrl'] = pageData.url;
    sData['pageName'] = pageData.pagename
    sData['targetSection'] = targetSection;
    sData['description'] = pageData.description;
    sData['data'] = data;


    let ip: any = await this.getLocalIPAddress();
    sData['ip'] = ip;
    let deviceInfo = this.deviceService.getDeviceInfo();
    sData['browser'] = deviceInfo?.browser;
    sData['os'] = deviceInfo?.os;
    sData['deviceType'] = deviceInfo?.deviceType;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        sData['longitude'] = position.coords.longitude;
        sData['latitude'] = position.coords.latitude;
        // console.log(sData.longitude,'geolocation')

      });
    } else {
      sData['longitude'] = 0
      sData['latitude'] = 0;
    }
    let finalData = {
      admin_id: _access == null ? 0 : _access?.id, activity_type: activity_type,
      data: sData, infosource: 'code', ip_address: ip,
      isLoader: 'false'
    }
    // console.log(finalData, 'finaldata')
    let response = await this.post('saveActivityLogs', finalData).toPromise();
    return response;
  }
  async getLocalIPAddress() {
    if (this.ip == '') {
      let ip: any = await this.get(environment.IP).toPromise();
      this.ip = ip['ip'];
      return this.ip;
    } else
      return this.ip;
  }
  isDirty: any = false;
  isDirtyFun(e: any) {
    this.isDirty = e;

  }

  async logoutForcefullyIdeal() {
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //     //returns installed service workers
    //     if (registrations.length) {
    //       for (let registration of registrations) {
    //         registration.unregister();
    //       }
    //     }
    //   });
    // }
    let ip = await this.getLocalIPAddress();
    if (ip != environment.ALLOWED_INTERNET_IP) {
      localStorage.clear();
      location.reload();
    }
  }

  // async addDataInActiveCampaign(course_id:any,firstname: any, lastname: any, email: any, phone: any, client_company_name: any, client_name: any, profile_type: any, client_type: any, user_type: any, client_tag: any, registrationdate: any, last_login: any): Promise<any> {

  //   if (course_id != 13 && course_id != 14 && course_id != 15 && course_id != 20 && course_id != 52) {
  //     console.log(`This course doesn't have permission.`, course_id);
  //     throw (`This course doesn't have permission.`)
  //     return
  //   }
  //   console.log(`This course have permission.`, course_id);

  //   if (client_type == 1) {
  //     profile_type = 'a2c';
  //   } else if (client_type == 2) {
  //     profile_type = 'd2c';
  //   } else {
  //     profile_type = 'education';
  //   }

  //   // if (client_tag === 'A&C') {
  //   //   client_tag = 'A2C';
  //   // }
  //   if (profile_type === 'a2c') {
  //     client_tag = 'A2C';
  //   } else if (profile_type === 'd2c') {
  //     client_tag = 'D2C';
  //   } else {
  //     client_tag = 'EDUCATION';
  //   }

  //   // Get Email Domain
  //   const domain = email.substring(email.indexOf('@') + 1);
  //   if (domain !== 'samosys.com' && domain !== 'eamailss.com' && domain !== 'mailinators.com') {
  //     const url = environment.activeCampaignUrl;
  //     const auth = environment.activeCampaignAuthKey;

  //     // const formData = new FormData();
  //     // formData.append('auth', auth);
  //     // formData.append('firstname', firstname);
  //     // formData.append('lastname', lastname);
  //     // formData.append('email', email);
  //     // formData.append('user_type', user_type);
  //     // formData.append('phone', phone);
  //     // formData.append('client_company_name', client_company_name);
  //     // formData.append('client_name', client_name);
  //     // formData.append('profile_type', profile_type);
  //     // formData.append('client_type', client_type);
  //     // formData.append('client_tag', client_tag);
  //     // formData.append('registrationdate', formatDate(new Date(), 'MM/dd/yyyy', 'en'));
  //     // formData.append('last_login', last_login);
  //     const contactData = {
  //       "contact": {
  //         "email": "test.doe@samosys.com",
  //         "firstName": "testing",
  //         "lastName": "test",
  //         "phone": "123-456-7890"
  //       }
  //     };

  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Api-Token': auth,
  //       'Custom-Header': 'CustomValue'
  //     });

  //     try {
  //       // Call Active Campaign API
  //       return await this.httpClient.post(url, contactData, { headers }).toPromise();
  //     } catch (error) {
  //       console.error('Error:', error);
  //       throw ('Something went wrong. Please try again later.');
  //     }
  //   } else {
  //     throw ('Invalid domain.');
  //   }
  // }
  postforfrontend(url: string, body: any, param?: any): Observable<any> {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.post(this.fronBaseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
  }

  async addDataInActiveCampaign(course_id: any, firstname: any, lastname: any, email: any, phone: any, client_company_name: any, client_name: any, profile_type: any, client_type: any, user_type: any, client_tag: any, registrationdate: any, last_login: any): Promise<any> {
    try {
    if (client_type == 1) {
      if (course_id != 13 && course_id != 14 && course_id != 15 && course_id != 20 && course_id != 52) {
        console.log(`This course doesn't have permission.`, course_id);
        throw (`This course doesn't have permission.`)
        return
      }
      console.log(`This course have permission.`, course_id);

      if (client_type == 1) {
        client_tag = 'A2C';
      } else if (client_type == 2) {
        client_tag = 'D2C';
      } else {
        client_tag = 'EDUCATION';
      }

      const profileTypeMapping: { [key: number]: string } = {
        1: 'Client',
        2: 'Prospect',
        3: 'Staff',
        4: 'Pilot',
        5: 'Former Client',
        6: 'Partner'
      };
      
      profile_type = profileTypeMapping[profile_type] || 'Prospect'; 
      
      if (email == '' || email == null) {
        return
      }

      // Get Email Domain
      const domain = email.substring(email.indexOf('@') + 1);
      if (domain !== 'samosys.com' && domain !== 'eamails.com' && domain !== 'mailinator.com' && domain !== 'enrichedacademy.com' && domain !== 'test.com') {

        let finalData: any = {
          firstname: firstname,
          lastname: lastname,
          email: email,
          user_type: user_type,
          phone: phone,
          client_company_name: client_company_name,
          client_name: client_name,
          client_status: profile_type,
          client_type: client_type,
          client_tag: client_tag,
          registrationdate: formatDate(new Date(), 'MM / dd / yyyy', 'en'),
          last_login: last_login,
        }
        console.log("finalData===",finalData);
        this.postforfrontend('/api/addActiveCampaign', finalData).subscribe(res => {
        })
      } else {
        throw ('Invalid domain.');
      }
    }
  } catch (error) {
    console.log(error);
  }
  }


  async editDataInActiveCampaign(firstname: any, lastname: any, email: any, phone: any, client_company_name: any, client_name: any, profile_type: any, client_type: any, user_type: any, client_tag: any, registrationdate: any, last_login: any): Promise<any> {
    if (client_type == 1) {
      if (client_type == 1) {
        client_tag = 'A2C';
      } else if (client_type == 2) {
        client_tag = 'D2C';
      } else {
        client_tag = 'EDUCATION';
      }

      const profileTypeMapping: { [key: number]: string } = {
        1: 'Client',
        2: 'Prospect',
        3: 'Staff',
        4: 'Pilot',
        5: 'Former Client',
        6: 'Partner'
      };

      profile_type = profileTypeMapping[profile_type] || 'Prospect';

      if (email == '' || email == null) {
        return
      }

      // Get Email Domain
      const domain = email.substring(email.indexOf('@') + 1);
      if (domain !== 'samosys.com' && domain !== 'eamails.com' && domain !== 'mailinator.com' && domain !== 'enrichedacademy.com' && domain !== 'test.com') {

        let finalData: any = {
          firstname: firstname,
          lastname: lastname,
          email: email,
          user_type: user_type,
          phone: phone,
          client_company_name: client_company_name,
          client_name: client_name,
          client_status: profile_type,
          client_type: client_type,
          client_tag: client_tag,
          registrationdate: formatDate(new Date(), 'MM / dd / yyyy', 'en'),
          last_login: last_login,
        }
        this.postforfrontend('/api/editActiveCampaign', finalData).subscribe(res => {
        })
      } else {
        throw ('Invalid domain.');
      }
    }
  }


}
