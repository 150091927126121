export const NgxUiLoaderConfigObj = {
    bgsColor: '#0295c9',
    bgsOpacity: 0.5,
    // bgsPosition: POSITION.bottomLeft,
     bgsSize: 60,
    // bgsType: SPINNER.chasingDots,
    blur: 9,

    // delay: 0,
    
    fastFadeOut: true,
    fgsColor: '#0295c9',
    overlayBorderRadius: "0",
    overlayColor: "rgba(40, 40, 40, 0.8)",
    // fgsPosition: POSITION.centerCenter,
    // fgsSize: 60,
    // fgsType: SPINNER.chasingDots,
    // gap: 24,
    // logoPosition: POSITION.centerCenter,
    // logoSize: 120,
    // logoUrl: 'assets/angular.png',
    // overlayBorderRadius: '0',
    // overlayColor: 'rgba(40, 40, 40, 0.8)',
    pbColor: 'green'
    // pbDirection: PB_DIRECTION.leftToRight,
    // pbThickness: 5,
    // hasProgressBar: false,
    // text: 'Welcome to ngx-ui-loader',
    // textColor: '#FFFFFF',
    // textPosition: POSITION.centerCenter,
    // maxTime: -1,
    // minTime: 500
  };
  