let BASE_URI = 'https://staging.admin.api.enrichedacademy.com';
// let BASE_URI = 'http://192.168.0.69:4005';
let ASSETS_URI = 'https://staging.assets.enrichedacademy.com/';
let WEB_URI = "https://staging.new.enrichedacademy.com";
let FRONTEND_BASE_URI = "https://staging.new.enrichedacademy.com"

export const environment = {
  production: false,
  assetsUrl: ASSETS_URI,
  apiURL: BASE_URI + '/admin/',
  apiUrl: BASE_URI,
  hmr: false,
  serverErrorMessage: 'Something went wrong in Server!',
  WEB_URI: WEB_URI,
  auth_token: 'Bearer eyJhbGciOiJIUzI1NiJ9.ZW5yaWNoZWQtYWNlZGVteS1zZWNyZXQta2V5.1lXirDpqBV7RjzNpwuM94uVykAv5-rgtyM7CVD1sQKs',
  title: 'Enriched Academy - Admin',
  // Setmoment: moment.tz.setDefault("EDT")
  IP:"getIP",
  VIMEO_BASE_URI:"https://player.vimeo.com/video/",
  apiUrlFrontend: BASE_URI,
  Learning_Api_URL: BASE_URI + '/learning/',
  FRONTEND_BASE_URI:FRONTEND_BASE_URI,
  passwordFormat: '^(?!.*\\s)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
  passwordFormat1: '^(?!.*\\s)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$',
  strongPassword: '(?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
  mediumPassword: '((?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))',
  
  DasboardCompaniesServiceRoute: '/dashboardCompaniesService',
  DashboardCompaniesAdminServiceRoute: '/dashboardCompaniesAdminService',
  DashboardClientServiceRoute: '/dashboardClientService',
  DashboardUsersServiceRoute: '/dashboardUsersService',
  adminManageAccountCompaniesServiceRoute: '/adminManageAccountCompaniesService',
  adminManageAccountClientsServiceRoute: '/adminManageAccountClientsService',
  adminManageAccountUsersServiceRoute: '/adminManageAccountUsersService',
  
  isVerifiedIP : '103.15.67.74',
  getNetworkIp : 'https://api64.ipify.org?format=json',
  ALLOWED_INTERNET_IP : '103.15.67.74',

  activeCampaignUrl:'https://www.enrichedacademy.com/lp/wp-json/webinar/v1/register_client_prospect',
  activeCampaignAuthKey:'dhYzU2NWFjMTE4OnIyM3A2V2dXWVlYU3k0R0hyOEFXU1NtWQ==',


};

export const commonconfig = {
  swalTitle: 'Are you sure?',
  swalText: "You want to delete",
  swalIcon: 'warning',
  swalbtn: 'btn btn-primary',
  swalconfirmbtn: '#7367F0',
  swalconfirmtext: 'Yes, delete it!',
  swalbtn2: 'btn btn-danger ml-1',
  swalcancelbtn: '#E42728',
  pagedatalimit1: '10',
  pagedatalimit2: '20',
  pagedatalimit3: '50',
  pagedatalimit4: '100',
  pagedatalimit5: '500',
  pagedatalimitentries: 'entries',
  status1: 'Active',
  status1value: '1',
  status2: 'Inactive',
  status2value: '0',
  sourcetypeAmazon: 'Amazon',
  sourcetypeAmazonvalue: '4',
  sourcetypeLocal: 'Local',
  sourcetypeLocalvalue: '3',
  sourcetypeVimeo: 'Vimeo',
  sourcetypeVimeovalue: '1',
  sourcetypeYoutube: 'Youtube',
  sourcetypeYoutubevalue: '2',
  agegroupAdults: 'Adults',
  agegroupAdultsvalue: '3',
  agegroupKids: 'Kids',
  agegroupKidsvalue: '1',
  agegroupOldAged: 'Old Aged',
  agegroupOldAgedvalue: '4',
  agegroupTeens: 'Teens',
  agegroupTeensvalue: '2',
  agegroupOther: 'Other',
  agegroupOthervalue: '5',
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
