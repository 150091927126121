import { Observable, throwError } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpEvent, HttpInterceptor, HttpClient, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { AuthService } from 'src/app/services/auth.service';
import { AuthenticationService } from 'app/auth/service';
import { HttpService } from './http.service';
// import { activityType } from 'src/app/modules/activityType'
// import { targetSection } from 'src/app/modules/targetSection'

@Injectable({
  providedIn: 'root'
})
export class LearningHttpService {

  // private baseUrl = environment.Learning_Api_URL;
  private baseUrl = environment.FRONTEND_BASE_URI;
  AUTH_TOKEN = environment.auth_token;
  language = localStorage.getItem('language') ?? 'en';
  lngData: any = [];
  ip: any = '';
  // targetSection = targetSection;
  // activityType: any = activityType;
  constructor(private httpClient: HttpClient, private deviceService: DeviceDetectorService, private AuthenticationService: AuthenticationService,
    private toastr: ToastrService, private ngxLoader: NgxUiLoaderService,
    private httpService: HttpService) {
    this.getLocalIPAddress()
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoaderShow = req.params.get('isLoaderShow');
    if ((req.body != null && req.body.isLoaderShow == false) || isLoaderShow == 'false') this.ngxLoader.stop();
    else {
      this.ngxLoader.start();
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) this.ngxLoader.stop();
    },
      (err: any) => this.ngxLoader.stop())); //Hide Loader
  }

  get(url: string, params?: any): Observable<any> {
    // const data = {params, headers: this.getAuthHeader()};
    const headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, ip_address: this.ip };
    const config = { headers: headers, params: params };
    return this.httpClient.get(this.baseUrl + url, config).pipe(catchError(this.errorHandler.bind(this)));
    // .get(this.baseUrl + url, data).pipe(catchError(this.errorHandler.bind(this)));
  }

  post(url: string, body: any, param?: any): Observable<any> {
    // const headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, ip_address: this.ip };
    // const config = { headers: headers };
    // body.ip_address = this.ip;
    // return this.httpClient.post(this.baseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
    let headers
    if (param) {
      headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
      const config = { headers: headers };
      body.ip_address = this.ip;
      return this.httpClient.post(this.baseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
    }
    else {
      headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, ip_address: this.ip };
      const config = { headers: headers };
      body.ip_address = this.ip;
      return this.httpClient.post(this.baseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
    }
  }

  put(url: string, body: any, params?: any): Observable<any> {
    return this.httpClient.put(this.baseUrl + url, body).pipe(catchError(this.errorHandler.bind(this)));
  }

  delete(url: string, params?: any): Observable<any> {
    return this.httpClient.delete(this.baseUrl + url).pipe(catchError(this.errorHandler.bind(this)));
  }

  getIPAddress() {
    return this.httpService.get(environment.IP);
  }

  async getLocalIPAddress() {
    let storedData = localStorage.getItem('ip');
    if (storedData) {
      let data
      data = JSON.parse(storedData);
    }
    else {
      if (this.ip == '') {
        let ip: any = await this.httpService.get(environment.IP).toPromise();
        this.ip = ip['ip'];
        localStorage.setItem('ip', JSON.stringify(this.ip));
        return this.ip;
      } else
        return this.ip;
    }
  }



  private errorHandler(response: any) {
    // this.toastr.error('', environment.serverErrorMessage);
    console.log(response, environment.serverErrorMessage)
    const error = response.error;
    const keys = Object.keys(error);
    const key = keys[0];
    let message = error[key];
    if (response.status === 401) {
      // auth token delete
      // redirect login page
    }
    if (error[key] instanceof Array) {
      message = error[key][0];
    }
    if (key === 'isTrusted') {
      // this will occur when not connected to internet
    } else {
      message = key + ' : ' + message;
    }
    // call snackbar and show error with message
    return throwError({ messages: message, error });
  }

  private getAuthHeader(): { [header: string]: string | string[]; } {
    return {
      Authorization: `Bearer ${localStorage.getItem(this.AUTH_TOKEN)}`
    };
  }

}
