export const activity_type = {
      user_loged_in: 'user_loged_in',

     
      button_clicked: 'button_clicked',
      submit_button_clicked: 'submit_update_clicked',
      

      export_button_clicked: "export trigger",
      Bulk_upload_clicked:"bulk upload trigger",
      companies_clicked: 'Add Companies save trigger',
      campanies_delete_clicked:"company delete trigger",
      companies_status_clicked:"company status trigger",
      comments_delete_clicked: "comments delete trigger",
      comments_status_clicked: "comments status trigger",
      reply_button_clicked: 'reply trigger',
      blog_delete_clicked: "blog delete trigger",
      blog_status_clicked: "blog status trigger",
      blog_save_clicked: "blog save button trigger",
      email_Template_clicked : "email template submit trigger",
      email_template_status_clicked: "email template status trigger",
      email_template_delete_clicked: "email template delete trigger",
      user_management_clicked : "user management submit trigger",
      user_management_status_clicked : "user manage status trigger",
      user_management_delete_clicked : "user manage delete trigger",
      Client_Admin_clicked: " client admin save trigger",
      Client_Admin_status_clicked: "client admin status trigger",
      Client_Admin_delete_clicked: "Client admin delete trigger",
      Client_temp_delete_clicked: "Client temporary delete trigger",
      Client_temp_clicked: " Client temporary activate trigger",
      Client_clicked: " client Next trigger",
      Client_status_clicked: "client status trigger",
      Client_delete_clicked: "Client delete trigger",
      Client_permission_next_clicked: "client permission next trigger",
      Client_Certificate_next_clicked: "client Certificate Customization next trigger",
      Client_social_links_next_clicked:"client social links next trigger",
      Client_course_config_next_clicked: "client course next trigger",
      Client_course_config_save_clicked: "client course Save trigger",
      Company_admins_clicked: "company admins save trigger",
      Company_admin_status_clicked:"company admins status trigger",
      Company_admin_delete_Clicked:"company admins delete trigger",
      Staffs_clicked: "staffs submit trigger",
      Staffs_status_clicked: "staffs status trigger",
      Staffs_delete_cliched: "staffs delete trigger",
      roles_clicked: "roles submit trigger",
      roles_status_clicked: "roles status trigger",
      roles_delete_cliched: "roles delete trigger",
      profile_image_clicked: "imame upload trigger",
      profile_change_clicked: "profile change trigger",
      change_password_clicked: "change password trigger",
      logout_button_clicked: "user logout trigger",
      sign_in_button_clicked:"user sign in trigger",
      like_dislike_clicked: "like dislike trigger",
      forgot_pass_continue_trigger: "forgot password trigger",
      otp_verify_trigger: "otp verify trigger",
      otp_reset_trigger: "otp_reset trigger",
      set_new_password_trigger : "set new password trigger",

      otp_generated: 'otp',
      delete_button_clicked: 'delete_button_clicked',
      edit_button_clicked: 'edit button clicked',
      add_button_clicked: 'add button clicked',
      status_button_clicked: 'status button clicked',



      module_delete_button_clicked: 'module deleted',
      module_edit_button_clicked: 'module edited',
      module_add_button_clicked: 'module added',
      module_add_button_clicked_external:'module added from external',
      module_status_button_clicked: 'module status updated',

      topic_delete_button_clicked: 'topic deleted',
      topic_edit_button_clicked: 'topic edited',
      topic_add_button_clicked: 'topic added',
      topic_status_button_clicked: 'topic status updated',

      video_delete_button_clicked: 'video deleted',
      video_edit_button_clicked: 'video edited',
      video_add_button_clicked: 'video added',
      video_status_button_clicked: 'video status updated',

      download_delete_button_clicked: 'downloadable tools deleted',
      download_edit_button_clicked: 'downloadable tools edited',
      download_add_button_clicked: 'downloadable tools added',
      download_status_button_clicked: 'downloadable tools status updated',

      expert_delete_button_clicked: 'expert deleted',
      expert_edit_button_clicked: 'expert edited',
      expert_add_button_clicked: 'expert added',
      expert_status_button_clicked: 'expert status updated',

      footer_banner_delete_button_clicked: 'footer banner deleted',
      footer_banner_edit_button_clicked: 'footer banner edited',
      footer_banner_add_button_clicked: 'footer banner added',
      footer_banner_status_button_clicked: 'footer banner status updated',

      age_group_delete_button_clicked: 'age group deleted',
      age_group_edit_button_clicked: 'age group edited',
      age_group_add_button_clicked: 'age group added',
      age_group_status_button_clicked: 'age group status updated',

      books_delete_button_clicked: 'books deleted',
      books_edit_button_clicked: 'books edited',
      books_add_button_clicked: 'books added',
      books_status_button_clicked: 'books status updated',

      course_delete_button_clicked: 'course deleted',
      course_status_button_clicked: 'course status updated',
      course_edit_button_clicked: 'course edited',

      course_next_knowledge_level_button_clicked2 :'edited module of course details',
      course_next_knowledge_level_button_clicked: 'Assign module to course details',
      course_next_assign_module_button_clicked: 'added course details in course overview ',
      course_next_assign_module_button_clicked2: 'edited course details in course overview ',


      course_next_essential_tiles_button_clicked: 'Assign question to course detais',
      course_next_resource_button_clicked: 'Assign tile to course detais',
      course_next_events_webinar_button_clicked: 'Assign resource to course detais',
      course_next_expert_interview_button_clicked: 'Assign events/webinar to course detais',
      course_next_contacts_social_media_button_clicked: 'Assign expert interview to course detais',
      course_next_social_link_button_clicked: 'Assign contact banner to course detais',
      course_submit_button_clicked: 'Assign social links to course detais',

      question_delete_button_clicked: 'question deleted',
      question_edit_button_clicked: 'question edited',
      question_add_button_clicked: 'question added',
      question_status_button_clicked: 'question status updated',

      tiles_delete_button_clicked: 'tiles deleted',
      tiles_edit_button_clicked: 'tiles edited',
      tiles_add_button_clicked: 'tiles added',
      tiles_status_button_clicked: 'tiles status updated',

      events_and_webinars_delete_button_clicked: 'events and webinars deleted',
      events_and_webinars_edit_button_clicked: 'events and webinars edited',
      events_and_webinars_add_button_clicked: 'events and webinars added',
      events_and_webinars_status_button_clicked: 'events and webinars status updated',

      email_log_delete_button_clicked: 'email log deleted',
      email_log_edit_button_clicked: 'email log edited',
      email_log_add_button_clicked: 'email log added',
      email_log_status_button_clicked: 'email log status updated',

      industry_edit_button_clicked:'industry edited',
      industry_add_button_clicked:'industry added',
      industry_delete_button_clicked:'industry deleted',
      industry_status_button_clicked:'industry status changed'


}